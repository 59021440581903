import TelegramIcon from '../../assets/icons/social/telegram.svg'
import InstagramIcon from '../../assets/icons/social/instagram.svg'
import OpencarsIcon from '../../assets/opencars.svg'

import styles from './Footer.module.css'
import { FC } from 'react'

const Footer: FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.gridContainer}>
        <div className={styles.container}>
          <p className={styles.title}>Підтримка</p>
          <ul>
            <li className={styles.menuItem}>
              <a className={styles.menuLink} href='mailto:support@opencars.app'>hello@opencars.app</a>
            </li>
            <li className={styles.menuItem}>
              <a className={styles.menuLink} href='tel:380935374841'>+380-93-537-4841</a>
            </li>
          </ul>
        </div>

        <div className={styles.container}>
          <p className={styles.title}>Відділ продажу</p>
          <ul>
            <li className={styles.menuItem}>
              <a className={styles.menuLink} href='mailto:support@opencars.app'>sales@opencars.app</a>
            </li>
            <li className={styles.menuItem}>
              <a className={styles.menuLink} href='tel:380935374841'>+380-93-537-4841</a>
            </li>
          </ul>
        </div>

        <div className={styles.container}>
          <p className={styles.title}>Продукти</p>
          <ul>
            <li className={styles.menuItem}>
              <a className={styles.menuLink} href='https://t.me/opencarsbot'>Телеграм Бот</a>
            </li>
            <li className={styles.menuItem}>
              <a className={styles.menuLink} href='https://docs.opencars.app/'>Розробникам</a>
            </li>
          </ul>
        </div>

        <div className={styles.container}>
          <p className={styles.title}>Слідкуй за нами тут:</p>
          <div className={styles.social}>
            <a className={styles.socialIcon} href='https://t.me/OpenCarsApp'>
              <img alt='Telegram' src={TelegramIcon} />
            </a>
            <a className={styles.socialIcon} href='https://www.instagram.com/opencars_app'>
              <img alt='Instagram' src={InstagramIcon} />
            </a>
          </div>
        </div>
      </div>

      <div className={styles.banner}>
        <div>
          <img className={styles.logo} alt='Logo' src={OpencarsIcon} />
        </div>
        <div>
          <p className={styles.font14}>www.opencars.app</p>
          <p className={styles.font14}>2019 - 2022. Всі права захищені.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
